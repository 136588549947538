<template>
  <div class="login-section-form-data" v-if="$locale">
    <div class="login-section-title">{{ $locale["lost-pass-btn"] }}</div>
    <div class="login-section-data">
      <form id="login-data-form" v-on:submit.prevent>
        <div class="block-input">
          <label>{{ $locale["new-pass-label"] }}</label>
          <input type="password" name="pass" :placeholder="$locale['input-password']" required @blur="validateInput($event, { tooltip: false })" />
        </div>
        <div class="block-input">
          <label ellipsis>{{ $locale["repeat-new-pass-label"] }}</label>
          <input type="password" name="repass" :placeholder="$locale['input-password']" required @blur="validateInput($event, { tooltip: false })" />
        </div>
        <div class="block-input">
          <input type="nosubmit" :value="$locale['change-pass-btn']" readonly @click="sendData('login-data-form')" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    validateInput: function($event, $config) {
      return this.$validate.validateInput($event, $config);
    },
    sendData: function(formID) {
      const code = this.$route.query.code;
      const email = this.$route.query.email ?? localStorage.getItem("lostpass");
      const isValidForm = this.$validate.validateForm(`#${formID}`, { tooltip: false });
      if (isValidForm) {
        this.ialert.go({ type: "loader" });
        const _data = new FormData(document.getElementById(formID));
        _data.append("code", code);
        _data.append("email", email);
        const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/setpass", _data);
        sedData.then((response) => this.sendSuccess(response));
        sedData.catch((response) => this.sendError(response));
      }
    },
    sendSuccess: function(response) {
      if (response?.data?.success) {
        this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["lost-pass-title"] });
        this.$router.push(`/login`);
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["error-auth-title"] });
      }
    },
    sendError: function(response) {
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
  },
};
</script>
